<template>
  <div class="top-navs">
    <nav>
        <router-link to="/">Home</router-link>
        <router-link to="/about">About Us</router-link>
        <router-link to="/Services">Services</router-link>
        <router-link to="/Careers">Careers</router-link>
        <router-link to="/ContactUs">Contact Us</router-link>
    </nav>
  </div>
</template>

<script>
export default {

}
</script>

<style>

.top-navs nav{
  position: absolute;
  background-color: rgba(0, 0, 0, 0.253);
  right: 10%;
  color: whitesmoke;
  font-family: 'Raleway', sans-serif;
  z-index: 2;
}

.top-navs nav a {
  padding: 20px;
  font-weight: bold;
  text-decoration: none;
  color: #ffde59;
  line-height: 50px;
}

.top-navs nav a.router-link-exact-active {
  color: white;
}


</style>