import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import Services from "../views/Services.vue";
import Careers from "../views/Careers.vue";
import Contact from "../views/Contact.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/services",
    name: "Services",
    component: Services
  },
  {
    path: "/careers",
    name: "Careers",
    component: Careers
  },
  {
    path: "/contactUs",
    name: "Contact",
    component: Contact
  },  
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
