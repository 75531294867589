<template>
    <div class="main-logo">
        <h1>ANM <br> Advisory<br> Services</h1>
        <p>Business and Financial advisory</p>
    </div>
  
</template>

<script>
export default {

}
</script>

<style>

.main-logo {
 position: absolute;
 margin: 20px;
 z-index: 2;
}

.main-logo h1 {
  font-size: 24px;
  font-family: 'Playfair Display SC', serif;
  color: #ffde59;
  font-weight: bold;
}

.main-logo p {
  margin: -5px 0px 20px 0px;
  width: 250px;
  color: white;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  font-size: 14px;
  word-spacing: 1px;
}

@media screen and (max-width: 767px){
    .main-logo h1{
        font-size: 20px;
    }

    .main-logo p{
        font-size: 14px;
    }
}


</style>