<template>
  <div class="career">
    <h1 id="careers-h1">Work with us</h1>
    <div class="workInputs" >
      <div class="work-in-group">
        <label for="name">Name: </label>
        <input type="text" id="workName" required />
      </div>
      <div class="work-in-group">
        <label for="email">Email: </label>
        <input type="text" id="workEmail" required />
      </div>
      <div class="work-in-group">
        <label for="name">Phone: </label>
        <input type="text" id="workPhone" required />
      </div>
      <div class="work-in-group">
        <label for="name">Position: </label>
        <select name="posList" id="workPos" required>
          <option value="">Select Position</option>
          <option value="Management Trainee">Management Trainee</option>
          <option value="Software Engineer">Software Engineer</option>
          <option value="Software Engineer Intern">Software Engineer Intern</option>
          <option value="Articleship">Articleship</option>
          <option value="CA">CA</option>
          <option value="Accountant">Accountant</option>
          <option value="Analyst">Analyst</option>
          <option value="Analyst Intern">Analyst Intern</option>
        </select>
      </div>
      <div class="work-in-group">
        <label for="name">Resume/CV: </label>
        <input type="file" v-on:change="previewfile" id="fileButton" name="filename" required />
        <div class="prog-bar">
          <p>Progress: {{ uploadValue.toFixed()+"%" }}
            <progress id="uploader" :value="uploadValue" max="100"> 0% </progress>
          </p>
        </div>
      </div>
      <div class="work-in-group">
        <button v-on:click="onUpload">Submit</button>
      </div>      
    </div>   
  </div>
  <foot />
</template>

<script>
/* eslint-disable */
import foot from "../components/footer";
import firebase from "firebase";
export default {
  components: {
    foot
  },
  data() {
    return{
      fileData: null,
      file: null,
      uploadValue:0

    }
  },
  methods:{
    previewfile(event){
      this.uploadValue=0;
      this.file=null;
      this.fileData=event.target.files[0];
    },
    onUpload(){
      this.file=null;
      const storageRef = firebase.storage().ref(`${this.fileData.name}`).put(this.fileData);
      storageRef.on('state_changed',snapshot=>{
        this.uploadValue=(snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error=>{console.log(error.message)},
      ()=>{this.uploadValue=100;
      storageRef.snapshot.ref.getDownloadURL().then((url)=>{
        this.file=url;
        });
      });

      this.submitJobApp();
    },
    submitJobApp(){
      console.log("This was Executed");
      const nameToSave = document.querySelector("#workName").value;
      const emailToSave = document.querySelector("#workEmail").value;
      const phoneToSave = document.querySelector("#workPhone").value;
      const posToSave = document.querySelector("#workPos").value;
      if( nameToSave == "" || emailToSave == "" || phoneToSave == ""){
        alert("Please fill all details");
      }else{
        console.log( "Submitted " + nameToSave + emailToSave + phoneToSave + posToSave);
        Email.send({
          Host: "smtp.office365.com",
          Username: "anmadvisory@outlook.com",
          Password: "consult@ANM123",
          To: 'ashish.b@anmindia.com',
          From: "anmadvisory@outlook.com",
          Subject: "New Job Application!",
          Body: 'Name: ' + nameToSave + '|| Email: ' + emailToSave + '|| Phone: ' + phoneToSave + '|| Position: ' + posToSave + '|| Resume: ' + this.fileData.name
        })
          .then(function (message) {
            alert("We`ll get back to you shortly");
            location.reload();
          });
      }
    
    }   

  }
}
</script>

<style>
.career{
  text-align: center;
  background-color: #2e2e38;
  margin: 0;
  padding: 0;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
#careers-h1{
  font-family: 'Raleway', sans-serif;
  margin: 175px 100px 50px 100px;
  font-weight: bolder;
  font-size: 2.2vw;
}

.workInputs .work-in-group{
  padding: 20px 0px 20px 0px;
}

.workInputs .work-in-group label{
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.2vw;
  font-weight: bold;
  display: flex;
  justify-content: start;
}

.workInputs .work-in-group input{
  width: 100%;
  background-color: #1a1a24;
  padding: 5px;
  margin-top: 5px;
  border: none;
  font-weight: bold;
  color: white;

}
.workInputs .work-in-group select{
  width: 100%;
  background-color: #1a1a24;
  padding: 8px;
  margin-top: 5px;
  border: none;
  font-weight: 700;
  color: white;

}

#uploader{
  -webkit-appearance: none;
  appearance: none;
}
.prog-bar{
  margin: 10px 0px 0px 10px;
  display: flex;
  justify-content:flex-end;
} 
.prog-bar p{
  font-family: 'Source Sans Pro', sans-serif;
  padding-right: 10px;
}

.workInputs .work-in-group button{
  width: 50%;
  border: solid 1px white;
  font-size: 18px;
  color: white;
  padding: 6px;
  background-color: #1a1a24;
  margin-bottom: 100px ;
}

@media screen and (max-width: 767px){

  #careers-h1{
    font-size: 28px;
  }

  .workInputs .work-in-group label{
    font-size: 20px;
  }

}

</style>
