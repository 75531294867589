import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import firebase from "firebase/app";
require('firebase/firestore')

createApp(App)
  .use(store)
  .use(router)
  .mount("#app");

  var firebaseConfig = {
    apiKey: "AIzaSyDttDY44LtM4Z2OyPgFiRXyCCM4sj6OZ3A",
    authDomain: "anm-advisory-4fe64.firebaseapp.com",
    projectId: "anm-advisory-4fe64",
    storageBucket: "anm-advisory-4fe64.appspot.com",
    messagingSenderId: "768286985518",
    appId: "1:768286985518:web:000cb1e0e7de333988bebf"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  export const db = firebase.firestore();
  export var st = firebase.storage();
