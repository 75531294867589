<template>
<div class="home-main">
  <section class="sec1">
      <div class="right-sec1">
        <div class="introduction">
          <div class="intro-msg">
            <h1>Let`s start with ' WHY '</h1>
            <p>Our work follows a simple path of serving people from all societies in a way that 
                makes their lives more sustainable. We believe that our work envisions a nobler purpose of 
                building endeavours that fuel our societies. The businesses we work with are strategized and simulated
                to not only create profits but also to have a promising impact towards people`s lives.
                We value our customers and provide each one with a personalized experience. 
                Our clients are a part of the ANM family and have continued to seek our expertize for the past 30 - 35 years. 
            </p>
          </div>
        </div>
      </div>
  </section>
  <section class="sec2">
   <div class="vision">
     <h1>Our Vision</h1>
     <p>Established in 1988 by Ashish Bhatnagar, ANM & Associates provides Chartered Accountant services and has transformed itself in the last 32 years and stood the test of time to grow in strength from a small firm to technology driven system based chartered accountant firm in Delhi, near CP. 
       Our vision is to keep growing and providing the best Account Tax, Account GST, GST in Tax consultancy, Information on GST, Income Tax Consulting, Audit, Assurance, Risk Advisory, Tax Advisory, Corporate Advisory, Business Consultancy & Advisory and Outsourcing Services across India. 
        </p>
    </div>
  </section>  
  <div class="abshelp">
    <h1>We`ll Help you</h1>
  </div> 
  <section class="sec3">
    <div class="abservices">
      <div v-on:mouseover="abs(2)" v-on:mouseout="abs(2)" class="magic">
        <h1 v-show="absmagic" class="magic-content"> Dream Big<br>and <br> Innovate <br> for a better<br> Tomorrow 
        </h1>
      </div>
      <div v-on:mouseover="abs(1)" v-on:mouseout="abs(1)" class="plant">
        <h1 v-show="absplant" class="plant-content"> Grow your <br>ideas <br>into a <br>business
        </h1>
      </div>
      <div v-on:mouseover="abs(3)" v-on:mouseout="abs(3)" class="phone">
        <h1 v-show="absphone" id="phone-h1" class="phone-content"> Administer <br> through  better <br> Communication   
        </h1>
      </div>
      <div v-on:mouseover="abs(4)" v-on:mouseout="abs(4)" class="chess">
        <h1 v-show="abschess" class="chess-content"> Strategize <br> your next <br> Move
        </h1>
      </div>
    </div>
  </section>
  <div class="book-now">
    <div class="book-now-head">
      <h2 id="book-now-h2">Book an Appointment Now!</h2>
      <p id="book-now-disc">Drop in your Name and Phone Number <br>We`ll reach you</p>
    </div>
    <form id="book-now-ingroup">
      <input type="text" class="bookInput" id="bookInName" placeholder="Full Name" required>
      <input type="number" class="bookInput" id="bookInPh" placeholder="Phone Number" required>
      <button type="submit" v-on:click="bookFn" id="bookButton">Send</button>
    </form>
  </div>
   <foot />
</div>
</template>

<script>
/* eslint-disable */ 
import foot from "../components/footer";

export default {
  name: "Home",
  components: {
    foot
  },

  data() {
    return {
      dest: 'Careers',
      absplant:false,
      absmagic: false,
      absphone:false,
      abschess:false
     }
  },

  methods:{
    abs(num) {
      if(num == 1)
      {
        this.absplant = !this.absplant;

      }
      else if (num == 2)
      {
        this.absmagic = !this.absmagic;

      }

      else if(num == 3)
      {
        this.absphone = !this.absphone;

      }
      else if(num == 4)
      {
        this.abschess = !this.abschess;

      }  
    },

    bookFn() {
      const bName = document.querySelector('#bookInName').value;
      const bPh = document.querySelector('#bookInPh').value;
      if(bName == "" || bPh == "")
      {
        alert("Please fill all details");
      }else{
        console.log(bName + ' ' + bPh);
        document.getElementById("book-now-ingroup").reset();
        Email.send({
          Host: "smtp.office365.com",
          Username: "abhyudyabhatnagar@live.in",
          Password: "ANMfrombAc98",
          To: 'ashish.b@anmindia.com',
          From: "abhyudyabhatnagar@live.in",
          Subject: "New Appointment!",
          Body: bName + ' ' + bPh
        })
          .then(function (message) {
            alert("We`ll get back to you shortly");
            
          });
      }  
    }

  }
};
</script>

<style>

.home-main{
  background-color: #2e2e38;
  margin: 0;
  padding: 0;
}

.sec1{
  display: flex;
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  background: linear-gradient(179.95deg, rgba(37, 56, 79, 0.97) 18%, rgba(37, 56, 79, 0.98) 32.62%, rgba(37, 56, 79, 0.85) 79.64%), url(../assets/City.jpg);
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; 
}

.right-sec1{
  display: flex;
  margin-left: auto;
  color: white;
  width: 70%;
}
.sec1 .introduction{
  background-color: rgba(0, 0, 0, 0.253);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  
}

.introduction .intro-msg{
  text-align: center;
}

.introduction h1 {
  font-family: 'Raleway', sans-serif;
  font-weight: bolder;
  font-size: 40px;
}

.introduction p {
  font-family: 'Source Sans Pro', sans-serif;
  word-spacing: 3px;
  font-weight: 800px;
  font-size: 1.3vw;
  background-color: rgba(0, 0, 0, 0.253);
  padding: 30px;
  color: #ffde59;
}

@media screen and (max-width: 767px) {

  .right-sec1{
    width: 100%;
  }

  .introduction h1{
    width: 100%;
    font-size: 28px;
    padding-top: 20px;
  }
  
  .introduction p{
    font-size: 15px;
    width: 100%;
  }

}

/* Vision */

.sec2{
  background-color: #2e2e38;
  height: 60vh;
  width: 100%; 
  display: flex;
  justify-content: center; 

}

.sec2 .vision {
  width: 50%;
  margin: 50px;
  text-align: center;

}

.vision h1 {
  font-family: 'Raleway', sans-serif;
  font-weight: bolder;
  font-size: 2.3vw;
  color: white;
}

.vision p {
  font-family: 'Source Sans Pro', sans-serif;
  word-spacing: 3px;
  font-weight: 800px;
  font-size: 1.2vw;
  background-color: rgba(0, 0, 0, 0.253);
  padding: 20px;
  color: #ffde59;
}

@media screen and (max-width: 767px){
  .sec2{
    width: 100%;
  }
  .sec2 .vision{
    width: 100%;
    margin: 0;
  }

  .vision h1{
    font-size: 32px;
  }

  .vision p{
    width: 100%;
    font-size: 15px;
  }
  
}

/* Abstract Services */

.abshelp {
  border-top: solid 2px #ffde59 ;
  background-color: #2e2e38;
  font-family: 'Raleway', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;

}

.abshelp h1{
  font-weight: bold;
  padding: 10px;
  color: white;
}

.sec3 {
  width: 100%;
  
}

.sec3 .abservices {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 75vh;
  resize: both;
  background-color: #2e2e38;
  color: white;
  display: flex;
  font-family: 'Raleway', sans-serif;
  text-align: center;
  border-bottom:solid 2px #ffde59;

}


.abservices .magic {
  background: url(../assets/magic.jpg);
  background-size: 45% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 25%;
  width: 25%;
  height: 70vh;
  background-repeat: no-repeat; 
  background-attachment: fixed;
}

.abservices .magic:hover {
  background:  linear-gradient(180deg, rgba(0, 0, 0, 0.726) 28.13%, rgba(0, 0, 0, 0.795) 100%), url(../assets/magic.jpg);
  transition: 1.5s;
  background-size: 40% 100%;
  width: 25%;
  height: 70vh;
  background-repeat: no-repeat; 
  background-attachment: fixed;
  background-position: left;
}


.abservices .plant {
  background: url(../assets/plant11.jpg);
  background-size: 75% 100%;
  width: 25%;
  height: 70vh;  
  min-width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat; 
  background-attachment: fixed;
  
}

.abservices .plant:hover {
  background:  linear-gradient(180deg, rgba(0, 0, 0, 0.726) 28.13%, rgba(0, 0, 0, 0.795) 100%), url(../assets/plant11.jpg);
  transition: 1.5s;
  background-size: 70% 100%;
  width: 25%;
  height: 70vh;
  background-repeat: no-repeat; 
  background-attachment: fixed;
}

.abservices .phone {
  background: url(../assets/phone.jpg);
  background-size: 80% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 25%;
  width: 25%;
  height: 70vh;
  background-repeat: no-repeat; 
  background-attachment: fixed;
  background-position: left;
}

.abservices .phone:hover {
  background:  linear-gradient(180deg, rgba(0, 0, 0, 0.726) 28.13%, rgba(0, 0, 0, 0.795) 100%), url(../assets/phone.jpg);
  transition: 1.5s;
  background-size: 85% 100%;
  width: 25%;
  position: relative;
  height: 70vh;
  background-repeat: no-repeat; 
  background-attachment: fixed;
  background-position: left;
}

.abservices .chess {
  background: url(../assets/chess.jpg);
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  min-width: 25%;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 70vh;
  background-repeat: no-repeat; 
  background-attachment: fixed;
  background-position: left;
}

.abservices .chess:hover{
  background:  linear-gradient(180deg, rgba(0, 0, 0, 0.726) 28.13%, rgba(0, 0, 0, 0.795) 100%), url(../assets/chess.jpg);
  transition: 1.5s;
  background-size: 105% 100%;
  width: 25%;
  height: 70vh;
  background-repeat: no-repeat; 
  background-attachment: fixed;
  background-position: left;
}

.abservices h1{
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  font-size: 2.5vw;
  padding: 10px;
  border:solid 2px #ffde59;
}


@media screen and (max-width: 767px){


.sec3 .abservices{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 105vh;
  
  }

  .abservices .magic {
    border: solid 3px #ffde59;
    margin-bottom:10px ;
    background-size: 100% 100%;    
    width: 90%;
    height: 20vh;
  }
  .abservices .magic:hover {
    background-size: 95% 100%;
    width: 50%;
    height: 40vh;
  }


  .abservices .plant {
    border: solid 3px #ffde59;
    margin-bottom:10px ;
    background: url(../assets/plant12.jpg);
    background-repeat: no-repeat ;
    background-attachment: fixed;
    background-size: 120% 100%;
    width: 90%;
    height: 20vh;
  }
  .abservices .plant:hover {
    background:  linear-gradient(180deg, rgba(0, 0, 0, 0.726) 28.13%, rgba(0, 0, 0, 0.795) 100%), url(../assets/plant12.jpg);
    background-repeat: no-repeat ;
    background-attachment: fixed;
    background-size: 125% 100%;
    width: 50%;
    height: 40vh;
  }


  .abservices .phone {
    border: solid 3px #ffde59;
    margin-bottom:10px ;
    background-size: 105% 100%;
    width: 90%;
    height: 20vh;
    padding-bottom: 100px;
  }
  .abservices .phone:hover {
    background-size: 110% 100%;
    width: 65%;
    height: 40vh;
  }
  #phone-h1{
    font-size: 24px;
    line-height: 1.7;
    padding: 4px;
  }

  .abservices .chess {
    border: solid 3px #ffde59;
    margin-bottom:10px ;
    background-size: 100% 100%;
    margin-bottom: 100px;
    width: 90%;
    height: 20vh;
  }

  .abservices .chess:hover {
    background-size: 105% 100%;
    width: 50%;
    height: 40vh;
  }

  .abservices h1{
    font-size: 24px;
    line-height: 1.7;
    padding: 10px;
  }

  

}

 /* Book NOw */

.book-now{
  color: white;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 100px 0px 100px 0px
}

.book-now-head {
  margin-top: 50px;
  width: 30%;
}

.book-now-head h2 {
  font-family: 'Raleway', sans-serif;
  font-weight: bolder;
  font-size: 32px;
}

.book-now-head p {
  color: #ffde59;
  font-size: 20px ;
  margin: 10px;
}
#book-now-ingroup{
  margin-top: 50px;
  width: 25%;
}

.bookInput{
  overflow: hidden;
  width: 100%;
  background-color: #1a1a24;
  color: white;
  font-size: 20px;
  padding: 8px;
  margin: 10px 10px 10px 0px;
}
#bookButton {
  font-size: 20px;
  margin: 15px;
  padding: 5px 15px 5px 15px;
  color: white;
  border: solid 1px white;
  border-radius: 3px ;
  background-color: #2e2e38;
}

#bookInPh{
  -webkit-appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (max-width: 767px){

  

.book-now{
  flex-direction: column;
}

.book-now-head{
  width: 80%;
}
.book-now-head h2{
  font-size: 24px;
}

.book-now-head p{
  font-size: 16px;
}

#book-now-ingroup{
  width: 50%;
  margin: 10px 10px 10px 0px;
}

.bookInput{
  font-size: 18px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#bookButton{
  margin-bottom: 50px;
}


}

</style>
