<template>
  
 <div class='footer'> 
  <div class="main-foot">

    <div class="left-foot">

      <div class="foot-logo">
        <h1>ANM <br> Advisory<br> Services</h1>
        <p>Business and Financial advisory</p>
      </div>

      <div class="disc">
        <p>ANM advisory services is an offspring of ANM & Associates, which is a Chartered Accountancy firm.<br>
          Along with the business and financial advisory, ANM offers all accountancy services such as Audtiting, Taxation, and GST.  
         </p>
      </div>

      <div class="foot-contact">
          <p>Phone: +91 011 23359295</p>
      </div>
    
    </div>

    <div class="right-foot">
      
      <div id="foot-navs"> 
        <router-link to="/">Home</router-link>
        <router-link to="/about">About</router-link>
        <router-link to="/Services">Services</router-link>
        <router-link to="/ContactUs">Contact </router-link>
        <router-link to="/Careers">Careers</router-link>
      </div>

    </div>

  </div>

  <small id="copyright">&copy; Copyright 2021, ANM Advisory Services LLP</small>

</div>

</template>

<script>
export default {

}
</script>

<style>


.footer {
  border-top: solid 1px #ffde59 ;
  position:relative;
  width: 100%;
  background-color: #2e2e38;
  color: white;
  margin: 0;
  padding: 20px;
}

.main-foot{
  display: flex;
  margin: 30px;
}

.left-foot{
  margin: 10px;
  width: 80%;
  /* margin: 15px 0px 0px 15px; */
  
}



.foot-logo h1{
  font-size: 20px;
  font-family: 'Playfair Display SC', serif;
  color: #ffde59;
  font-weight: bold;

}

.foot-logo p {
  margin: -5px 0px 20px 0px;
  width: 250px;
  color: white;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 12px;
  word-spacing: 2px;
}

.left-foot .disc{
  margin-right: 20px;
}

.left-foot .disc p{
  font-size: 15px;
}

.left-foot .foot-contact{
  display: flex;
  font-size: 14px;
}

.left-foot .foot-contact p{
  padding: 15px 15px 0px 0px;
}

.right-foot{
  /* margin: 25px 25px 0px 15px; */
  width: 20%;
}

.right-foot #foot-navs{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right-foot #foot-navs a{
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  /* padding: 8px 8px 0px 5px; */
  padding: 10px;
  text-decoration: none;
  color: white;
  font-size: 20px;


}

.right-foot #foot-navs a:hover{
  color: #ffde59;


}



.footer #copyright{
  font-size: 14px;
  display: inline-block;
  margin: 10px;
}

@media screen and (max-width: 767px){
  .main-foot{
  display: flex;
  margin: 0px;
}

.left-foot .disc p{
  font-size: 12px;
}

}



</style>