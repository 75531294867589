<template>
  <div class="mobile-navs" v-if="Vnav">
    <nav>
        <router-link to="/">Home</router-link><br>
        <router-link to="/about">About Us</router-link><br>
        <router-link to="/Services">Services</router-link><br>
        <router-link to="/Careers">Careers</router-link><br>
        <router-link to="/ContactUs">Contact Us</router-link>
    </nav>
  </div>
</template>

<script>
/* eslint-disable */ 
export default {
  props: {
    Vnav: {
      type: Boolean
    }

  },
  data() {
    return{
      mobileView: true,
      Vnav: true
    }
  },
  methods: {
    navFn(){
      this.mobileView = innerWidth <= 990;

    },
    hamButton(){
      console.log("hell yeh f yeh");
      this.Vnav = false;
    }
    
  },
  created() {
    this.navFn();
  }
};
</script>

<style>
.mobile-navs{
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-left:50%;
  height: 100vh;
  /* background-color: rgba(0, 0, 0, 0.671); */
  background-color: #2e2e38e3;

}

.mobile-navs nav{
  align-items: center;
  justify-content: center;
  color: whitesmoke;
  font-family: 'Raleway', sans-serif;
  z-index: 2;
}

.mobile-navs nav a {
  padding: 20px;
  font-weight: bold;
  text-decoration: none;
  color: #ffde59;
  line-height: 50px;
}

.mobile-navs nav a.router-link-exact-active {
  color: white;
}


</style>