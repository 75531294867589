<template lang="html">
  <div class="contact">
    <h1 id="contact-h1">Connect with us</h1>

    <div class="main-holder">
      <div class="first-half">

        <div class="in-group">
        <label for="inName">Name: </label>
        <input type="text" id="nameIn" name="inName" placeholder="Full Name" required>
        </div>

        <div class="in-group"> 
        <label for="Email" id="email">E-Mail: </label>
        <input type="text"  id="emailIn" placeholder="mark@xyz.com" required><br>
        </div>

        <div class="in-group">
        <label for="Phone">Phone No: </label>
        <input type="text"  id="phoneIn" placeholder="+91 98112233547" required><br>
        </div>

        <div class="in-group">
        <label for="subject">Subject: </label>
        <input type="text"  Id="subjectIn" placeholder="General Enquiry"><br>
        </div>

        <div class="in-group">
        <label for="Message">Message: </label>
        <textarea type="text" rows="5" cols="50" id="messageIn" placeholder="Message... "></textarea><br>
        </div>

        <div class="in-group">
         <button v-on:click="submitfn" id="contButton">Send Message</button>
        </div>
      </div>

      <div class="line"></div>

      <div class="second-half">        
        <div id="map"> 
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.9863772687586!2d77.2300297150362!3d28.630170382418786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd2c2bf904eb%3A0x915d2515ff327aec!2s31%2C%20Todarmal%20Ln%2C%20Todermal%20Road%20Area%2C%20Mandi%20House%2C%20New%20Delhi%2C%20Delhi%20110001!5e0!3m2!1sen!2sin!4v1615146538546!5m2!1sen!2sin" 
            allowfullscreen="" loading="fast"></iframe>
        </div>
      </div>

    </div>

    <foot />
      
  </div>
</template>
<script>
/* eslint-disable */ 
import { db } from "../main";
import foot from "../components/footer"
export default {
  components: {
    foot
  },
  data() {
    return{}
  },
  methods: {
    submitfn(){
      const nameToSave = document.querySelector("#nameIn").value;
      const emailToSave = document.querySelector("#emailIn").value;
      const phoneToSave = document.querySelector("#phoneIn").value;
      const subjectToSave = document.querySelector("#subjectIn").value;
      const messageToSave = document.querySelector("#messageIn").value;
      console.log( "Submitted " + nameToSave + emailToSave + phoneToSave + subjectToSave + messageToSave);
      if( nameToSave == "" || emailToSave == "" || phoneToSave == ""){
        alert("Please fill all details");
      }else{
        db.collection("contMail").add({
            name: nameToSave,
            email: emailToSave,
            phone: phoneToSave,
            subject: subjectToSave,
            message: messageToSave
        })
        .then((docRef) => {
            console.log("Document written with ID: ", docRef.id);
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
        });
        this.mailQuery();

      }
    },
    mailQuery(){
      console.log("Mail was Executed");
      const nameToSave = document.querySelector("#nameIn").value;
      const emailToSave = document.querySelector("#emailIn").value;
      const phoneToSave = document.querySelector("#phoneIn").value;
      const subjectToSave = document.querySelector("#subjectIn").value;
      const messageToSave = document.querySelector("#messageIn").value;
      
      console.log( "Emailed " + nameToSave + emailToSave + phoneToSave);
        Email.send({
          Host: "smtp.office365.com",
          Username: "anmadvisory@outlook.com",
          Password: "Nb4ZXc44wHb+S)y",
          To: 'ashish.b@anmindia.com',
          From: "anmadvisory@outlook.com",
          Subject: "New Query!",
          Body: 'Name: ' + nameToSave + '|| Email: ' + emailToSave + '|| Phone: ' + phoneToSave + '|| Subject: ' + subjectToSave + '|| Message: ' + messageToSave
        })
          .then(function (message) {
            alert("We`ll get back to you shortly");
            location.reload();
            
          });
      
    }
   
  }
  
}
</script>

<style>


*{
  margin: 0;
  padding: 0;
}

 .contact {
  height: 100vh;
  width: 100%;
  background-color: #2e2e38;
  margin: 0;
  padding: 0;

}

 #contact-h1 {
  color: white;
  margin: 0px;
  padding: 100px 100px 50px 100px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-weight: bolder;
  font-size: 2.6vw;
}

.main-holder{
  display: flex;
  justify-content: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.3vw;
  background-color: #2e2e38;
  padding-bottom: 150px ;
}

.first-half{
  width: 50%;

}

.first-half .in-group{
  margin: auto;
  width: 50%;
  padding: 10px;
  color: white;
}

.first-half .in-group input {
  width: 100%;
  padding: 5px;
  background-color: #1a1a24;
  color: white;
  overflow: hidden;
  border: none;
  margin: 0;

}


.first-half .in-group textarea {
  width: 100%;
  background-color: #1a1a24;
  color: white;
  resize: none;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  border: none;
  margin: 0;
}

#phoneIn {
  overflow: hidden;
  
}

.first-half .in-group label {
  font-family: 'Source Sans Pro', sans-serif;
  display: inline-block;
  margin-bottom: 3px;
}

#contButton{
  font-family: 'Source Sans Pro', sans-serif;
  padding: 5px;
  background-color: #1a1a24;
  color: white;
  border: solid 1px white;
}


.line{
  height: 70vh;
  width: 3px;
  background-color: #ffde59 ;
}

.second-half{
  width: 50%;
}

#map{
width: 50%;  
margin: auto;
margin-top: 40px;
}

#map iframe{
  width: 100%;
  border: solid 2px #ffde59;
  height: 50vh;
  padding: 10px;
}

@media screen and (max-width: 767px){

  #contact-h1 {
  color: white;
  width: 100%;
  margin: 0px;
  padding: 175px 0px 20px 0px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-weight: bolder;
  font-size: 28px;
}

.main-holder{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.first-half .in-group{
  width: 100%;
}
.first-half .in-group label {
  font-size: 20px ;
  display: inline-block;
  margin-bottom: 3px;
}

#contButton{

  font-size: 20px;
}

.first-half .in-group input{
  font-size: 20px;
}
.first-half .in-group textarea{
  font-size: 20px;
}

.line{
margin: 20px 0px 20px 0px ;
height: 2px;
width: 75%;
background-color: #ffde59 ;
}
.second-half{
  width: 100%;
}
#map{  
margin: auto;
margin-top: 20px;
}
#map iframe{
  width: 100%;
  border: solid 2px #ffde59;
  height: 30vh;
  padding: 10px;
}


}

</style>
