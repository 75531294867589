<template>
  <div class="services">
    <div class="serv-head">
      <h1 id="services-h1">Services We Offer</h1>
    </div>

    <div class="serv-vid-container">
      <video autoplay muted loop id="serv-vid">
        <source src="../assets/mountains.mp4" type="video/mp4">
      </video>
      <h2 id="serv-quote">If you can clearly articulate the dream or the goal, start</h2>
    </div>

    <div class="main-services">
      
      <div class="serv-group">
        <img src="../assets/serv/women2.jpg" width='400' height="300" alt="tax">
        <h2 class="serv-h2">{{ services.s1 }}</h2>
        <p class="serv-disc"> <ul>
            <li>Identifying corporate tax planning opportunities available to minimize the overall tax burden based upon prevalent / acceptable practices and development in law.</li>
            <li>Providing updates on key changes in income tax law affecting Client operation in India</li>
            <li>Providing a comprehensive range of tax advisory and compliance services in respect of routine corporate tax issues;</li>
            <li>Preparation of tax computations for discharging advance tax liability, if any, on June 15, September 15, December 15 and March 15 every year</li>
          </ul> </p>
      </div>
      <div class="serv-group">
        <img src="../assets/serv/library.jpg" width='400' height="300" alt="tax">
        <h2 class="serv-h2">{{ services.s2 }}</h2>
        <p class="serv-disc">We provide complete support for processing of employees and vendors payable and 
          ensure proper accounting of all business transactions made by the client in accordance with 
          generally accepted accounting principles in India, which would include:
          <ul>
            <li>Maintenance of Books of Accounts in accordance with Indian GAP impacting Client Operations in India</li>
            <li>Provide MIS support to the Client as per their requirements on specified periodicity</li>
            </ul>          
          </p>
      </div>
      <div class="serv-group">
        <img src="../assets/serv/desk.jpg" width='400' height="300" alt="tax">
        <h2 class="serv-h2">{{ services.s3 }}</h2>
        <p class="serv-disc">
          <ul>
            <li>Structuring tax efficient compensation packages of the Expatriate / Local personnel currently employed by the client and calculating monthly tax withholdings based on such structures</li>  
            <li>Maintaining control over deadlines in connection with the deposit of withholding taxes of the employees</li>
            <li>Preparation of Salary Certificates in Form 16 and filing of quarterly return of salaries for the Company</li>
            <li>Advisory services on routine personal tax issues.</li>
            </ul> </p>            
      </div>
      <div class="serv-group">
        <img src="../assets/serv/women.jpg" width='400' height="300" alt="tax">
        <h2 class="serv-h2">{{ services.s4 }}</h2>
        <p class="serv-disc">
          We render advisory and compliance services on routine transactions/ issues, 
          under the Companies Act, 1956 & Exchange Control regulations, which would include:
          <ul>
            <li>Copies of relevant resolutions as per the requirement of law.</li>
            <li>Any other routine Form / Resolutions/ Agreements.</li>
            <li>Filling other details/ information, as may be requested by ROC.</li>
            <li>Advising on changes in exchange control law that affects operations in India</li>
            <li>Advising on specific issues relating to exchange control regulations </li>
          </ul>
           </p>
      </div>
      <div class="serv-group">
        <img src="../assets/serv/forklift.jpg" width='400' height="300" alt="tax">
        <h2 class="serv-h2">{{ services.s5 }}</h2>
        <p class="serv-disc">We also render advisory and compliance services, under GST Act, which would include:
          <ul>
          <li>Preparation and filing of GST returns in accordance with the law</li>  
          <li>Prepare reconciliation of Input and output taxes in books with GST portal.</li>
          <li>Advising on changes in GST law that affects operations in India;</li>
          </ul> </p>
          
      </div>
      <div class="serv-group">
        <img src="../assets/serv/bonsai.jpg" width='400' height="300" alt="tax">
        <h2 class="serv-h2">{{ services.s6 }}</h2>
        <p class="serv-disc">
          <ul>
            <li>Advising on the issues related to withholding taxes on the payments to be made by the company to various suppliers and service providers.</li>
            <li>Preparation of withholding tax certificates in Form No. 16A in connection with payments towards rent, professional fees, etc.</li>
            <li>Preparation and filing of Quarterly returns of tax withheld.</li>
            </ul> </p>
      </div>
  
    </div>
    
    <foot />
  </div>


</template>

<script>
/* eslint-disable */ 
import foot from "../components/footer";
export default {
  components: {
    foot
  },
  data() {
    return{
      services:{
        s1: 'Direct Tax',
        s2: 'Accounting Services',
        s3: 'Personal Tax and Compliance',
        s4: 'Corporate Law & Exchange Control Regulations',
        s5: 'Goods and Service Tax',
        s6: 'Withholding and Tax Compliance'
      },

      servDisc: {
        s1: "Identifying corporate tax planning opportunities available to minimize the overall tax burden based upon prevalent / acceptable practices and development in law.\n Providing updates on key changes in income tax law affecting Client operation in India",
        s2: 'Service 2 disc nmmmmmmmmmmmmmmmmmmmmmmmmmmmm',
        s3: 'Service 3 disc nmmmmmmmmmmmmmmmmmmmmmmmmmmmm',
        s4: 'Service 4 disc nmmmmmmmmmmmmmmmmmmmmmmmmmmmm',
        s5: 'Service 5 disc nmmmmmmmmmmmmmmmmmmmmmmmmmmmm',
        s6: 'Service 6 disc nmmmmmmmmmmmmmmmmmmmmmmmmmmmm'
      }

    }
  }

}
</script>

<style>

.services{
  width: 100%;
  background-color: #2e2e38;
  margin: 0;
  padding: 0;
  color: white;

}

.serv-head{
  width: 100%;
  height: 22vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:flex-end;
}

#services-h1{
  text-align: center;
  background-color: #2e2e38d5;
  z-index: 1;
  font-family: 'Raleway', sans-serif;
  font-weight: bolder;
  font-size: 2.2vw;
}

#serv-quote{
  position: absolute;
  padding: 10px;
  top: 35vh;
  left: 25%;
  width: 50%;
  text-align: center;
  background-color: #2e2e38b6;
  border-left: solid 4px #ffde59 ;
  border-right: solid 4px #ffde59 ;
  z-index: 1;
  font-family: 'Raleway', sans-serif;
  font-weight: bolder;
  font-size: 2vw;

}

.serv-vid-container{
}

#serv-vid{
  object-fit: cover;
  width: 100%;
  height: 50vh;
}

.main-services{
  margin: 50px 0px 100px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.serv-group{
  display: flex;
  width: 90%;
  background-color: #1a1a24;
  margin: 5px 0px 15px 0px;
  padding: 20px;
}
.serv-group h2{
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  padding: 50px;
}
.serv-group p{
  padding: 50px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
}

.serv-group p ul li{
  list-style:square;
}

@media screen and (max-width: 767px){
  
  #serv-vid{
    height: 40vh;
  }
  #serv-quote{
    font-size: 24px;
  }
  .serv-group{
    width: 100%;
    display: block;
    text-align: center;
    padding: 0;
  }
  .serv-group img{
    margin-top: 10px;
    width: 300px;
    height: 280px;
    
  }
  .serv-group p{
  padding: 0px;
  }
  #services-h1{
    font-size: 28px;
  }

}
@media screen and (max-width: 767px){
  #services-h1{
    font-size: 25px;
  }
  
}



</style>
