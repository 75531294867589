<template>
  <div class="logo">
    <Logo />
  </div>
  <Navmobile id="Navmobile"/>

  <button class="hamburger" id="hamburger" v-if="mobileView" v-on:click="hamButton">
     <i class="fa fa-bars" aria-hidden="true"></i>    
  </button>
  <Navigation v-if="!mobileView" />
 
<router-view />

</template>

<script>
/* eslint-disable */ 
import Navigation from './components/navigation.vue';
import Navmobile from './components/navmobile.vue';
import Logo from './components/logo.vue';


export default {
  name:"App",
  components: {
    Navigation,
    Navmobile,
    Logo   
    },
  data(){
    return{
      mobileView: false,
      Vnav: false
    }
  },
  methods: {
    navFn(){
      this.mobileView = innerWidth <= 990;

    },
    hamButton(){
      console.log("hell yeh f yeh");
      var navMob = document.querySelector("#Navmobile");

      navMob.classList.toggle('show');

      }    
  },
  created() {
    this.navFn();
  } 
};
</script>

<style>


*{
 margin: 0;
 padding: 0; 
}

.hamburger{
  position: absolute;
  right: 5%;
  background-color: transparent;
  border: 0;
  color: white;
  font-size: 40px;
  cursor: pointer;
  z-index: 2;
}
.hamburger:focus{
  outline: none;
}

#Navmobile{
  display: none;
}

#Navmobile.show{
  display: flex;
}

@media screen and (max-width: 767px) {

  .hamburger{
    font-size: 40px;
    font-weight: lighter;
  }
}



</style>
